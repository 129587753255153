<template>
    <div class="chart">
        <div class="leftbox">
            <div class="buttonBox">
                <!-- <el-button size="small" type="primary" @click="addcart">新建类别</el-button>
                <el-button size="small" type="danger" @click="deletecart">删除类别</el-button> -->
            </div>
            <div class="tableBox">
                <el-table
                    :data="tableData"
                    :tree-props="{ children: 'children' }"
                    row-key="dsName" 
                    border
                    style="width: 100%"
                    height="100%"
                    @row-click="rowClick"
                    highlight-current-row
                    @selection-change="listSelectionChange"
                    >
                    <!-- <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column label="序号" type="index" width="70" align="center"></el-table-column>
                    <el-table-column prop="catName" label="图表大类" align="center"></el-table-column> -->
                    <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column label="序号" type="index" width="70" align="center"></el-table-column>
                    <el-table-column prop="dsName" label="数据集名称" align="center"></el-table-column>
                    <el-table-column prop="connName" label="数据库连接" align="center"></el-table-column>
                    <el-table-column prop="dsType" label="数据集类型" align="center"></el-table-column>
                </el-table>
            </div>
        </div>
        <div class="rightbox">
            <div class="buttonBox">
                <el-button size="small" type="primary" @click="addtable">新建图表</el-button>
                <el-button size="small" type="danger" @click="datildeletecart">删除图表</el-button>
            </div>
            <div class="tableBox">
                <el-table
                    :data="tableDataname"
                    border
                    style="width: 100%"
                    height="100%"
                    @selection-change="ditallistSelectionChange"
                    >
                    <!-- <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column label="序号" type="index" width="70" align="center"></el-table-column>
                    <el-table-column prop="catName" label="图表名称" align="center"></el-table-column>
                    <el-table-column label="操作" width="120" align="center">

                        <template slot-scope="scope">
                            <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.row)"></el-button>
                        </template>
                    </el-table-column> -->
                    <!-- 数据集查询表单 -->
                    <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column label="序号" type="index" width="70" align="center"></el-table-column>
                    <el-table-column prop="catName" label="图表名称" align="center"></el-table-column>
                    <el-table-column label="操作" width="120" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                width="30%"
            >
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item label="大类名称">
                        <el-input v-model="formInline.catName" placeholder="大类名称"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisibleok">确 定</el-button>
                </span>
            </el-dialog>
    </div>
</template>
<script>
import { getLargeCategory, addsetLargeCategory, getChartShow, deleteLargeCategory, } from "@/api/chart/index.js"
import { getdataSetlist, getChartData } from "@/api/dataSet/index.js"
export default {
    name: 'chart',
    data () {
        return{
            tableData: [],
            tableDataname: [],
            dialogVisible: false,
            formInline: {
                catName: "",
                catType: 0,
                children: []
            },
            tableids: '',
            listdata: [],
            ditaldata: [],
            rowlist: {},
        }
    },
    mounted () {
        this.init()
    },
    methods: {
        rowClick (row, column, event) {
            this.tableids = row.id
            getChartData({datasetId: row.id}).then(res => {
                console.log('图表数据', res)
                this.tableDataname = res
            })
            // this.tableids = row.id
            // if (row.children && Array.isArray(row.children)) {
            //     this.tableDataname = row.children
            // } else {
            //     this.tableDataname = []
            // }


            /* 数据集点击 */

        },
        addcart () {
            this.dialogVisible = true
        },
        deletecart () {
            deleteLargeCategory(this.listdata).then(res => {
                this.init()
            })
        },
        datildeletecart () {
            deleteLargeCategory(this.ditaldata).then(res => {
                // this.init()
                for (let i = 0; i < this.ditaldata.length; i ++) {
                    for (let j = 0; j < this.tableDataname.length; j ++) {
                        if (this.ditaldata[i] == this.tableDataname[j].id) {
                            this.tableDataname.splice(j, 1)
                        }
                    }
                }
            })
        },
        listSelectionChange (selection) {
            this.listdata = []
            for (let i = 0; i < selection.length; i ++) {
                this.listdata.push(selection[i].id)
            }
            // this.listdata = selection
        },
        ditallistSelectionChange (selection) {
            this.ditaldata = []
            for (let i = 0; i < selection.length; i ++) {
                this.ditaldata.push(selection[i].id)
            }
            // this.ditaldata = selection
        },
        init() {
            /* 查询图表大类 */
            // getLargeCategory().then(res => {
            //     this.tableData = res
            // })
            /* 图表打类改成数据集 */
            getdataSetlist().then(res => {
                this.tableData = res
            })
        },
        dialogVisibleok () {
            addsetLargeCategory(this.formInline).then(res => {
                this.$message({
                    message: '保存成功',
                    type: 'success'
                })
                this.init()
            })
            this.dialogVisible = false
        },
        addtable () {
            if (this.tableids == "" || this.tableids == null || undefined) {
                this.$message({
                    message: '请先选择一条大类',
                    type: 'warning'
                })
            } else {
                this.$router.push({
                    name: "setTable",
                    params: {
                        id: this.tableids
                    }
                })
            }
            
        },
        handleEdit(row) {
            console.log(row)
            this.$router.push({
                name: "addTable",
                params: {
                    id: row.id,
                    dataId: row.datasetId,
                    name: row.catName,
                    heightList: row.canvasHeight
                }
            })
            // getChartShow({id: row.id}).then(res => {
            //     console.log('查询图表数据', res)
            // })
        }
    }
}
</script>
<style scoped lang="less">
.chart{
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 5px;
}
.leftbox {
    width: 40%;
    height: 100%;
    float: left;
    border-right: 1px solid black;
}
.rightbox {
    width: 59%;
    height: 100%;
    float: left;
}
.buttonBox {
    width: 100%;
    padding: 10px;
    min-height: 60px;
    box-sizing: border-box;
}
.tableBox {
    height: 85%;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
}
</style>